import { DEFAULT_FILTER } from 'features/job-offers/list/components/JobOffersListComponent.constants';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { getUrlParamsFromJobOffersFilter } from 'utils/job-offers-filter';
import { getLocale } from 'utils/localize.utils';
import TopbarCompaniesSubnav from './TopbarCompaniesSubnav';
import {
  StyledSubnav,
  StyledSubnavClose,
  StyledSubnavTitle,
  StyledTopbarNav,
  StyledTopbarNavItem,
  StyledTopbarNavItemIcon
} from './TopbarNav.styled';
import TopbarTalentSubnav from './TopbarTalentSubnav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { useState } from 'react';

const TopbarNav: React.FC = () => {
  const { t } = useI18n('topbar');
  const { pathname, locale } = useRouter();

  const getNavItemClassname = (path: string): string => {
    return pathname.startsWith(path) ? 'active' : '';
  };

  const isTalent = pathname.startsWith('/talent');
  const isCompanies =
    pathname.startsWith('/empresas') || pathname.startsWith('/companies');

  const { isEnabled: isCompaniesMenuEnabled } =
    useFeatureFlag('company-new-menu');

  const [talentSubNavActive, setTalentSubnavActive] = useState(false);
  const [companiesSubNavActive, setTCompaniesSubnavActive] = useState(false);

  return (
    <StyledTopbarNav
      aria-label={t('nav.label')}
      isTalent={isTalent}
      isCompanies={isCompanies}
    >
      <ul>
        <li className="desktopSubnavTrigger">
          <Link href={`${getAppUrl(APP_URLS.talent, locale)}`} passHref>
            <StyledTopbarNavItem className={getNavItemClassname('/talent')}>
              {t('nav.talent')}
            </StyledTopbarNavItem>
          </Link>
          <StyledSubnav>
            <TopbarTalentSubnav />
          </StyledSubnav>
        </li>
        <li className="mobileSubnavTrigger">
          <StyledTopbarNavItem
            as="button"
            className={getNavItemClassname('/talent')}
            onClick={() => setTalentSubnavActive(true)}
          >
            {t('nav.talent')}
            <StyledTopbarNavItemIcon>
              <FontAwesomeIcon icon={faArrowRight} />
            </StyledTopbarNavItemIcon>
          </StyledTopbarNavItem>
          <StyledSubnav className={talentSubNavActive ? 'is-active' : ''}>
            <StyledSubnavClose onClick={() => setTalentSubnavActive(false)}>
              <FontAwesomeIcon icon={faArrowLeft} />
              {t('talent_subnav.back')}
            </StyledSubnavClose>
            <StyledSubnavTitle>{t('nav.talent')}</StyledSubnavTitle>
            <TopbarTalentSubnav />
          </StyledSubnav>
        </li>
        <li className={isCompaniesMenuEnabled ? 'desktopSubnavTrigger' : ''}>
          <Link href={`${getAppUrl(APP_URLS.companies, locale)}`} passHref>
            <StyledTopbarNavItem className={getNavItemClassname('/companies')}>
              {t('nav.companies')}
            </StyledTopbarNavItem>
          </Link>
          {isCompaniesMenuEnabled && (
            <StyledSubnav>
              <TopbarCompaniesSubnav />
            </StyledSubnav>
          )}
        </li>
        {isCompaniesMenuEnabled && (
          <li className="mobileSubnavTrigger">
            <StyledTopbarNavItem
              as="button"
              className={getNavItemClassname('/talent')}
              onClick={() => setTCompaniesSubnavActive(true)}
            >
              {t('nav.companies')}
              <StyledTopbarNavItemIcon>
                <FontAwesomeIcon icon={faArrowRight} />
              </StyledTopbarNavItemIcon>
            </StyledTopbarNavItem>
            <StyledSubnav className={companiesSubNavActive ? 'is-active' : ''}>
              <StyledSubnavClose
                onClick={() => setTCompaniesSubnavActive(false)}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                {t('talent_subnav.back')}
              </StyledSubnavClose>
              <StyledSubnavTitle>{t('nav.companies')}</StyledSubnavTitle>
              <TopbarCompaniesSubnav />
            </StyledSubnav>
          </li>
        )}
        <li>
          <Link href={`${getAppUrl(APP_URLS.team, locale)}`} passHref>
            <StyledTopbarNavItem className={getNavItemClassname('/team')}>
              {t('nav.us')}
            </StyledTopbarNavItem>
          </Link>
        </li>
        <li>
          <Link
            href={`${getAppUrl(
              APP_URLS.jobOffers,
              locale
            )}?${getUrlParamsFromJobOffersFilter(
              DEFAULT_FILTER,
              getLocale(locale)
            )}`}
            passHref
          >
            <StyledTopbarNavItem className={getNavItemClassname('/job-offers')}>
              {t('nav.offers')}
            </StyledTopbarNavItem>
          </Link>
        </li>
        <li>
          <Link href={`${getAppUrl(APP_URLS.blog, locale)}`} passHref>
            <StyledTopbarNavItem className={getNavItemClassname('/blog')}>
              {t('nav.blog')}
            </StyledTopbarNavItem>
          </Link>
        </li>
      </ul>
    </StyledTopbarNav>
  );
};

export default TopbarNav;
